export default function transl(char) {
    if (process.env?.REACT_APP_EN_VERSION === "development") {
        return char;
    }
    const key = char.toLowerCase();
    const map = {
        preview: "미리보기",
        dashboard: "전체 현황 파악",
        projects: "프로젝트",
        submissions: "제출",
        submission: "제출",
        users: "사용자ID",
        "log out": "로그아웃",
        "add new project": "명부 리스트 새로 만들기",
        admin: "관리자",
        worker: "활동가",
        loading: "로딩중",
        publish: "진행 중",
        draft: "프로젝트 일시 중단",
        "save as draft": "작업 임시저장",
        deleted: "삭제",
        completed: "완료",
        "project submission": "실시간 현황",
        "check project submissions": "실시간 현황보기",
        "this project has no submissions yet.": "제출한 내역이 없습니다",
        "download files": "첨부파일 다운로드",
        "download csv data": "활동내역(엑셀) 다운로드",
        title: "프로젝트 명",
        "created at": "활동시작 날짜",
        no: "연번",
        company: "발행회사",
        "resident registration number": "실명번호",
        registration: "실명번호",
        "name+first 6 digits of resident registration number": "고유번호",
        sex: "성별",
        name: "주주명",
        "number of shares": "주식수",
        "total number of shares": "총소유주식수",
        address: "주소",
        contact: "주소서치",
        "contact info": "주소서치",
        "contact for worker": "연락처 정보",
        database: "구연락처",
        "worker(s)": "담당자",
        result: "판단",
        "my projects": "활동 프로젝트",
        "add new submission": "활동 보고",
        attachment: "첨부파일",
        edit: "편집",
        status: "현황",
        search: "검색",
        actions: "활동",
        home: "홈",
        chat: "채팅",
        settings: "설정",
        shareholders: "주주",
        "sort by": "정렬",
        "total of shares": "총소유주식수",
        results: "판단",
        all: "전체",
        "with no results": "미 활동내역",
        shares: "주식수",
        "total shares": "총소유주식수",
        project: "프로젝트",
        shareholder: "주주",
        date: "활동 날짜",
        password: "비밀번호",
        note: "특이사항",
        submit: "제출하기",
        "click here to upload files": "첨부파일(사진,오디오 등) 업로드",
        "go back": "돌아가기",
        "email or password invalid": "이메일 또는 비밀번호가 유효하지 않습니다",
        "sing in": "로그인",
        email: "이메일",
        "publish project": "프로젝트 시작",
        "complete project": "프로젝트 종료",
        "delete project": "프로젝트 삭제",
        "pause project": "일시정지(활동가에게숨기기)",
        color: "아이콘 색상",
        percentage: "각 판단별 진행 현황(%)",
        total: "전체",
        "edit shareholders list": "기존 주주명부 수정",
        "add more shareholders": "주주명부 추가",
        "person type": "비고",
        role: "권한",
        "create user": "ID생성",
        "search for shareholders": "검색하기",
        "send submission": "제출하기",
        "show only result": "보고된 내역",
        "previous submissions": "이전 보고 내역",
        "result name": "판단값",
        "result color": "판단 아이콘색상",
        "0 means no goal": "의결권발행주식 총수",
        goal: "목표주식수",
        "eletronic voting": "전자투표",
        "eletronic vote": "전자투표",
        "publish new project": "새 프로젝트 만들기",
        "type total": "총 합(주식수)",
        "type percentage": "총 합(%)",
        "please attach the required file before submitting the form":
            "제출하기 전에 필수 첨부파일을 첨부해주세요",
        "results is a required field": "판단은 필수 입력사항입니다",
        "The current result requires contact information for the worker":
            "현재 판단값은 담당자의 연락처 정보가 필요합니다",
        "Note is a required field": "특이사항은 필수 입력사항입니다",
        "download all the submission files.": "모든 제출 파일 다운로드하기",
        "real time results": "실시간 결과",
        "open quick view": "빠른 보기 열기",
        "close quick view": "빠른 보기 닫기",
        "start date": "시작 날짜",
        "end date": "종료 날짜",
        "not set": "미설정",
        "project updated with success":
            "프로젝트가 성공적으로 업데이트 되었습니다",
        "expired end date": "지났습니다",
        "days left": "일 남았습니다",
        "last day": "마지막 날",
        "edit project": "세부 사항 수정하기",
        "select folder": "첨부파일 다운로드",
        "go to project": "자세한 현황보기",
        "order results": "결과 정렬",
        "cancel updates": "취소",
        "do you really want to delete this shareholder?":
            "이 주주를 정말로 삭제하고 싶나요?",
        "shareholder name": "주주명",
        save: "저장",
        filters: "필터",
        sort: "정렬",
        "show all": "전체보기",
        "total number of shares issued": "총 발행 주식 수",
        "target number of shares": "목표 주식 수",
        "project start date": "활동 시작 일",
        "project end date": "주총 날짜",
        "add result": "판단 값 추가",
        "attachment required on submission":
            "활동 보고 시, 첨부 파일 기입 필수",
        "contact required on submission": "활동 보고 시, 연락처 기입 필수",
        "download sample": "엑셀 형식 다운로드",
        "submission completed with success": "활동 보고가 완료 되었습니다",
        "go back to project": "다시 주주명부로 돌아가기",
        "copy address": "주소 복사",
        "open in naver maps": "네이버 지도에서 보기",
        "activity report": "활동 보고 및 수정",
        "shareholder upload data": "주주 데이터 업로드",
        "download csv sample": "새 프로젝트 엑셀 형식",
        "download csv example": "새 프로젝트 엑셀 형식",
        "shareholder additions list": "주주명부 추가",
        "cancel editing": "편집 취소",
        menu: "메뉴",
        "upload receipt": "영수증 제출",
        "deadline warning": "영수증 제출 기한",
        "usage history": "사용 내역",
        "where were used": "사용처",
        "please fill all the fields, to be able to upload":
            "모든 필드를 작성해주세요",
        "fuel expense": "유류비",
        "food expenses": "식비",
        accommodation: "숙박비",
        "my receipts": "영수증 내역 보기",
        "no receipts": "영수증 내역이 없습니다",
        "click to see more details": "자세히 보기",
        receipt: "영수증",
        "are you sure you want to delete this receipt?":
            "정말로 삭제하시겠습니까?",
        "receipt details": "영수증 내역 확인",
        "no receipts found": "영수증 내역이 없습니다",
        "receipt deleted successfully": "영수증이 삭제되었습니다",
        "receipt deadline warning": "영수증 제출 기한",
        "deadline updated with sucesss":
            "영수증 제출 기한이 업데이트 되었습니다",
        "please fill all fields": "모든 필드를 작성해주세요",
        "receipts download": "영수증 다운로드",
        "filter the receipts using the filters, and click the button below to generate a zip file with all the receipts from the selection":
            "필터를 사용하여 영수증을 필터링하고 아래 버튼을 클릭하여 선택한 모든 영수증을 포함하는 zip 파일을 생성합니다.",
        "total receipt attachments selecteds": "선택된 영수증 첨부파일",
        "no receipt attachments selected": "선택된 영수증 첨부파일이 없습니다",
        "generate zip file": "zip 파일 생성",
        "download zip file": "zip 파일 다운로드",
        "search for receipt": "영수증 검색",
        amount: "금액",
        "view receipt": "영수증 보기",
        "view all receipt": "영수증 보기",
        "number of submissions": "제출 개수",
        "total amount": "총 금액",
        "download attachments": "첨부파일 다운로드",
        "view receipt details": "영수증 내역 보기",
        "preview attachment": "첨부파일 미리보기",
        "download attachment": "첨부파일 다운로드",
        "receipt updated with success": "영수증이 업데이트 되었습니다",
        "submission deadline": "제출마감",
        "current submission deadline": "현재 제출마감",
        "set new submission deadline": "새 제출마감 설정",
        "edit usage history details": "사용 내역 편집",
        "submission deadline updated with success":
            "제출마감이 업데이트 되었습니다",
        "project created with success": "프로젝트가 생성되었습니다",
        "leave the field empty to remove a usage history":
            "사용 내역을 삭제하려면 빈칸으로 남겨주세요",
        "please add at least one item to the usage history":
            "사용 내역을 추가해주세요",
        "updated with success": "업데이트 되었습니다",
        download: "다운로드",
        "select a user to show the submission form":
            "사용자를 선택하여 제출 양식을 표시합니다",
        "select user": "사용자 선택",
        "min 6 characters": "최소 6자리",
        "update password": "비밀번호 변경",
        "view total amount": "총 금액 보기",
        "deactivate user": "사용자 비활성화",
        "choose an option": "선택해주세요",
        delete: "삭제",
        "date is required": "날짜는 필수 입력사항입니다",
        "usage history is required": "사용 내역은 필수 입력사항입니다",
        "where were used is required": "사용처는 필수 입력사항입니다",
        "amount is required": "금액은 필수 입력사항입니다",
        "attachments are required": "첨부파일은 필수 입력사항입니다",
        "you have no receipts yet": "영수증 내역이 없습니다",
        "receipt attachment": "영수증 첨부파일",
        editing: "편집중",
        "deleted with success": "삭제되었습니다",
        "lock receipt submission": "영수증 제출 잠금",
        "notice funtion": "공지사항",
        "do you really want to lock receipt submission?":
            "영수증 제출을 잠그시겠습니까?",
        "do you really want to unlock receipt submission?":
            "영수증 제출을 잠금해제 하시겠습니까?",
        "select items to delete": "삭제할 항목 선택",
        "do you really want to delete these items?": "정말로 삭제하시겠습니까?",
        "first person": "1차",
        "no message displaying": "메시지가 없습니다",
        "project resources": "프로젝트 자료실",
        resources: "자료실",
        "click to upload": "클릭하여 업로드",
        "or drag and drop": "또는 드래그하여 업로드",
        "png, jpg, gif, pdf, csv up to 10mb":
            "PNG, JPG, GIF, PDF, CSV 최대 10MB",
        "file uploaded successfully": "파일이 성공적으로 업로드되었습니다",
        "no resources found": "자료실이 없습니다",
        "deleted  successfully": "삭제되었습니다",
        "are you sure you want to delete this resource?":
            "정말로 삭제하시겠습니까?",
        "view resource": "자료실 보기",
        "organize resources": "자료실 정리",
        "drag and drop to change resources position": "자료실 정렬",
        "activity data": "활동 데이터",
        "project name": "프로젝트명",
        "days of activity": "활동일",
        "total reports": "접촉 횟수",
        "total shares contacted": "접촉 총 소유주식수",
        "proxy shareholders": "위임 주주수",
        "owned shares by proxy": "위임 총 소유주식수",
        "number of workers": "총 활동가",
        "total number of proxy shareholders": "위임 주주수(총합)",
        "total number of shares owned by proxy": "위임 총 소유주식수(총합)",
        "download activity data": "활동 데이터 다운로드",
        "adding new submission": "새로운 활동 보고",
        "editing submission": "활동 보고 수정",
        "submission deleted successfully": "활동 보고가 삭제되었습니다",
        "are you sure you want to delete this submission?":
            "정말로 삭제하시겠습니까?",
        "download excel file": "엑셀 파일 다운로드",
        "show only last submission per shareholder": "주주별 최근 활동만 보기",
    };

    return map[key] ? map[key] : char;
}
