import {
	Chip,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import React from "react";
import transl from "../../components/translate";
import ClearIcon from "@mui/icons-material/Clear";
import OChip from "../../components/OChip";
import { isEditable } from "@testing-library/user-event/dist/utils";
import { useShareholderDelete } from "../../../hooks/useShareholder";
import { toast } from "react-toastify";

function ShareholderTable({ list, isEditble, projectResult }) {
	const stickyCell = "sticky left-0 bg-white z-20";
	const deleteShareholderMutation = useShareholderDelete();

	const handleDeleteShareholder = (id, name) => {
		if (
			window.confirm(
				`${transl(
					"Do you really want to delete this shareholder?"
				)} \n\n\n${transl("shareholder name")}: ${name} `
			) == true
		) {
			deleteShareholderMutation.mutate(
				{
					id: id,
				},
				{
					onSuccess: () => {
						toast.success("Shareholder deleted successfully", {
							position: "top-right",
							autoClose: 4000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					},
				}
			);
		}
	};

	return (
		<div className="p-4 ">
			<TableContainer
				className="border"
				sx={{
					maxHeight: 440,
					maxWidth: "100%",
					overflowX: "auto",
				}}
			>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell
								className={stickyCell}
								style={{ minWidth: 96, zIndex: 22 }}
							>
								{transl("no")}.
							</TableCell>
							<TableCell
								className={`${stickyCell} left-24`}
								style={{
									minWidth: 120,
									zIndex: 22,
									borderRight: "1px solid #e0e0e0",
								}}
							>
								{transl("name")}
							</TableCell>
							<TableCell style={{ minWidth: 100 }}>
								{transl("Resident Registration Number")}
							</TableCell>
							<TableCell style={{ minWidth: 100 }}>
								{transl("sex")}
							</TableCell>
							<TableCell style={{ minWidth: 200 }}>
								{transl("number Of Shares")}
							</TableCell>
							<TableCell style={{ minWidth: 200 }}>
								{transl("total Number Of Shares")}
							</TableCell>
							<TableCell style={{ minWidth: 300 }}>
								{transl("contact Info")}
							</TableCell>
							<TableCell style={{ minWidth: 300 }}>
								{transl("database")}
							</TableCell>
							<TableCell style={{ minWidth: 300 }}>
								{transl("contact for worker")}
							</TableCell>
							<TableCell style={{ minWidth: 300 }}>
								{transl("Eletronic Voting")}
							</TableCell>
							<TableCell style={{ minWidth: 300 }}>
								{transl("address")}
							</TableCell>
							<TableCell style={{ minWidth: 100 }}>
								{transl("worker")}
							</TableCell>
							<TableCell style={{ minWidth: 100 }}>
								{transl("Result")}
							</TableCell>
							<TableCell style={{ minWidth: 150 }}>
								{transl("person Type")}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{list
							.sort((a, b) => a.no - b.no)
							.map((value, key) => {
								var chip = "";
								if (isEditble && projectResult[value.result]) {
									chip = JSON.parse(
										projectResult[value.result]
									);
								}

								return (
									<TableRow key={key}>
										<TableCell className={stickyCell}>
											{isEditble && !value?.result && (
												<IconButton
													size="small"
													onClick={() =>
														handleDeleteShareholder(
															value.id,
															value.name
														)
													}
												>
													<ClearIcon
														sx={{ fontSize: 12 }}
													/>
												</IconButton>
											)}
											{value?.no}
										</TableCell>
										<TableCell
											className={`${stickyCell} left-24`}
											style={{
												borderRight:
													"1px solid #e0e0e0",
											}}
										>
											<div className="flex items-center">
												{value.name}
											</div>
										</TableCell>
										<TableCell>
											{value.registration}
										</TableCell>
										<TableCell>{value.sex}</TableCell>
										<TableCell>{value.shares}</TableCell>
										<TableCell>
											{value.shares_total}
										</TableCell>
										<TableCell>
											{value.contact_info}
										</TableCell>
										<TableCell>{value.database}</TableCell>
										<TableCell>
											{value.contact_worker}
										</TableCell>
										<TableCell>
											{value.eletronic_voting}
										</TableCell>
										<TableCell>{value.address}</TableCell>
										<TableCell>
											{value.user.map((value) => (
												<Chip
													key={value}
													label={value}
													size="small"
													variant="outlined"
													sx={{ marginBottom: "6px" }}
												/>
											))}
										</TableCell>
										<TableCell>
											<OChip color={chip.color}>
												{chip.name}
											</OChip>
										</TableCell>
										<TableCell>
											{value.person_type}
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default ShareholderTable;
