import React, { useState } from "react";
import transl from "../../../components/translate";
import { useResourceUpload } from "../../../../hooks/useFileUpload";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useResourceCreate } from "../../../../hooks/useResource";

function ResourcesUpload({ project }) {
    const inputFileUploadMutation = useResourceUpload();
    const [isLoading, setIsLoading] = useState(false);
    const createResourceMutation = useResourceCreate();

    const handleAttachmentUpload = (e) => {
        const filename = e.target.files[0].name;
        setIsLoading(true);

        inputFileUploadMutation.mutate(
            {
                project: project.title,
                filename: filename,
                file: e.target.files[0],
            },
            {
                onSuccess: (data) => {
                    // toast.success(transl("File uploaded successfully"), {
                    //     position: "top-right",
                    //     autoClose: 4000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    // });
                    // console.log("data", data);

                    handleResourceCreate(filename, data);
                },
            }
        );
    };

    const handleResourceCreate = (title, path) => {
        createResourceMutation.mutate(
            {
                resource: {
                    title: title,
                    path: path,
                    attachedTo: "project",
                    parentId: project.id,
                },
            },
            {
                onSuccess: (data) => {
                    toast.success(transl("File uploaded successfully"), {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    setIsLoading(false);
                },
            }
        );
    };

    return (
        <div className="flex items-center justify-center w-full">
            <div className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 relative">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    {isLoading ? (
                        <CircularProgress sx={{ mb: 4 }} />
                    ) : (
                        <svg
                            className="w-8 h-8 mb-4 text-gray-500 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                        </svg>
                    )}
                    <p className="mb-2 text-sm text-gray-500 ">
                        <span className="font-semibold">
                            {transl("Click to upload")}
                        </span>
                        {transl("or drag and drop")}
                    </p>
                    <p className="text-xs text-gray-500 ">
                        {transl("PNG, JPG, GIF, PDF, CSV up to 10MB")}
                    </p>
                </div>
                {!isLoading && (
                    <input
                        id="dropzone-file"
                        type="file"
                        className=" absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                        onChange={(event) => handleAttachmentUpload(event)}
                    />
                )}
            </div>
        </div>
    );
}

export default ResourcesUpload;
