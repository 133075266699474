export const COLOR = {
	lightShades: "#8D271A",
	lightAccent: "#952424",
	mainBrand: "#5a0713",
	darkAccent: "#2D333A",
	darkShades: "#0a0b0d",

	/*
	 * Secondary Colors
	 */
	text: "#2c323f",
	link: "#4D1254",
	warning: "#ff6900",
	success: "#22ab00",

	white: "#FFF",
	black: "#000",

	border: "#e5e5e5",

	gradient: "linear-gradient(145deg,#870000 0%,#7e0420 49%,#5a0713 100%)",

	superLight: "#f3f5f9",

	/*
	 * Components
	 */

	feature: "#9c27b0",
	fix: "#ff9800",
	idea: "#2196f3",
	examine: "#e91e63",
};
