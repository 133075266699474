import { atom } from "jotai";

//USER
export const currentUserAtom = atom(false);

//FILTER
export const listOfShareholdersAtom = atom(false);

// Submission Edit
export const submissionEditAtom = atom(false);

// APP BUTTON MENU
export const appDrawerOpenAtom = atom(false);
