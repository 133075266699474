export default function setShareholderUpdateList(csv) {
    let shareholders = [];

    csv?.forEach((value, key) => {
        if (key != 0) {
            // From CSV
            const id = value[0];
            const no = value[1];
            const registration = value[2];
            const shares = value[5];
            const shares_total = value[6];
            const eletronicVoting = value[7];
            const address = value[8];
            const contactInfo = value[9];
            const database = value[10];
            const contact_worker = value[11];
            const user = value[12];

            if (!id) {
                return false;
            }

            shareholders.push({
                id: id,
                no: no,
                registration: registration,
                shares: shares,
                shares_total: shares_total,
                eletronic_voting: eletronicVoting,
                contact_info: contactInfo,
                database: database,
                contact_worker: contact_worker,
                address: address,
                user: user.split("/"),
            });
        }
    });

    return shareholders;
}
