import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import transl from "../../../components/translate";

function ResourcesProjectLoopItem({ project }) {
    return (
        <Link to={`/dashboard/resources/${project.id}`}>
            <div className="relative p-4 mb-3 cursor-pointer overflow-hidden rounded-lg bg-white shadow-md transition-all hover:shadow-lg flex justify-between items-center">
                <p className="text-lg">
                    {project.title}
                    <span className="ml-2 text-sm text-slate-400">
                        {moment(project.created_at).format("YYYY/MM/DD HH:mm")}h
                    </span>
                </p>
                {project.resources && (
                    <div className="flex items-center">
                        <p className="text-green-500">
                            {project.resources.length}
                        </p>
                        {transl("Resources")}
                    </div>
                )}
            </div>
        </Link>
    );
}

export default ResourcesProjectLoopItem;
