import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import transl from "../../../components/translate";
import { useReceiptUpload } from "../../../../hooks/useFileUpload";
import ImageUploadHolder from "../../SingleShareholderApp/SubmissionForm/ImageUploadHolder";

const FileUploadStyled = styled.div`
    border: 2px solid #000;
`;

function ReceiptAttachForm({
    date,
    usageHistory,
    user,
    attachments,
    setAttachments,
}) {
    const inputFileUpload = useReceiptUpload();

    const handleAttachmentUpload = (e) => {
        // e.target.value = null;

        inputFileUpload.mutate(
            {
                file: e.target.files[0],
                date: date,
                filename: `${usageHistory}_${date}`,
                user: user,
            },
            {
                onSuccess: (data) => {
                    setAttachments((prev) => {
                        if (prev == []) {
                            return data;
                        }

                        return [...prev, data];
                    });
                },
            }
        );
    };

    const removeFile = (url) => {
        setAttachments(attachments.filter((item) => item !== url));
    };

    return (
        <div>
            <ImageUploadHolder
                isLoading={inputFileUpload.isLoading}
                files={attachments}
                removeFile={removeFile}
            />

            <FileUploadStyled className="relative flex items-center justify-center px-2 py-3 mb-4 rounded-md">
                {false ? (
                    <CircularProgress />
                ) : (
                    <p>{transl("Click here to upload files")}</p>
                )}

                <input
                    type="file"
                    onChange={(event) => handleAttachmentUpload(event)}
                    className="absolute left-0 top-0 w-full h-full opacity-0 z-10"
                />
            </FileUploadStyled>
        </div>
    );
}

export default ReceiptAttachForm;
