import { Button, Card, CardContent, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useCSVDownloader, usePapaParse } from "react-papaparse";
import { toast } from "react-toastify";
import transl from "../../../components/translate";
import ShareholderTable from "../../components/ShareholderTable";
import getFormatedShareholders from "./getFormatedShareholders";
import DownloadIcon from "@mui/icons-material/Download";

function ShareholdersUpload({ shareholderList, setShareholderList }) {
    const [isLoading, setIsloading] = useState(false);
    const { CSVDownloader } = useCSVDownloader();
    const csvDownloadHeader = [
        `${transl("no")}`,
        transl("Resident Registration Number"),
        transl("Sex"),
        transl("Name"),
        transl("Number of Shares"),
        transl("Total Number of Shares"),
        transl("Eletronic Voting"),
        transl("Address"),
        transl("Contact"),
        transl("Database"),
        "연락처",
        transl("Worker"),
    ];

    const handleOnChange = (e) => {
        const file = e.target.files[0];

        readString(file, {
            worker: true,
            complete: (results) => {
                const formatedShareholders = getFormatedShareholders(
                    results.data
                );
                if (!formatedShareholders?.length) {
                    toast.error(
                        "Something went wrong! Check your excel please.",
                        {
                            position: "top-right",
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                } else {
                    setShareholderList(formatedShareholders);
                }
                setIsloading(false);
            },
        });
        e.target.value = null;
    };

    const { readString } = usePapaParse();

    return (
        <Card className="mb-12">
            <CardContent>
                <div className="flex justify-between mb-3">
                    <p className="text-lg ">
                        {transl("Shareholder Upload Data")}
                    </p>
                    <CSVDownloader
                        filename={transl("Download CSV Sample")}
                        bom={true}
                        quotes={true}
                        config={{
                            quotes: true,
                        }}
                        data={{
                            fields: csvDownloadHeader,
                        }}
                    >
                        <Button>
                            <DownloadIcon />
                            {transl("Download Sample")}
                        </Button>
                    </CSVDownloader>
                </div>
                <div className="border-2 border-slate-300 border-dashed rounded-xl relative py-6 cursor-pointer">
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <p className="text-slate-500 text-center text-sm mb-2">
                                {transl("Click here or Drag your file")}
                            </p>
                            <p className="text-center font-bold text-slate-500">
                                {transl("Upload the Shareholders .CSV")}
                            </p>
                        </>
                    )}
                    <input
                        type="file"
                        accept=".csv, text/csv"
                        onChange={handleOnChange}
                        className="absolute left-0 top-0 w-full h-full opacity-0 z-10"
                    />
                </div>
            </CardContent>
            {shareholderList && <ShareholderTable list={shareholderList} />}
        </Card>
    );
}

export default ShareholdersUpload;
