import moment from "moment/moment";
import React from "react";
import formatNumber from "../../../components/formatNumber";

export default function getFormatedShareholders(csv) {
    let shareholders = [];

    csv?.forEach((value, key) => {
        if (key != 0 && value?.length > 1) {
            // From CSV

            const no = value[0];
            var registration = value[1];
            const sex = value[2];
            const name = value[3];
            const numberOfShares = value[4];
            const totalNumberOfShares = value[5];
            const eletronicVoting = value[6];
            const address = value[7];
            const contactInfo = value[8];
            const database = value[9];
            const contactForWorker = value[10];
            const worker = value[11];

            if (
                !(name && registration && numberOfShares && totalNumberOfShares)
            ) {
                return false;
            }

            registration = registration.trim();

            let personType = "person";
            let dob = registration.substr(0, 6);
            let dobA = registration;
            let code = registration.substr(6);

            if (registration?.length != 13) {
                personType = "business";
                code = registration;
                dob = registration;
            } else {
                dobA = {
                    year: registration.substring(0, 2),
                    month: registration.substring(2, 4),
                    day: registration.substring(4, 6),
                };
                dobA = `${dobA.year}/${dobA.month}/${dobA.day}`;

                dobA = moment(dob, "YY/MM/DD").valueOf();
            }

            shareholders.push({
                no: no,
                name: name,
                registration: registration,
                sex: sex,
                shares: formatNumber(numberOfShares),
                shares_total: formatNumber(totalNumberOfShares),
                contact_info: contactInfo,
                database: database,
                contact_worker: contactForWorker,
                eletronic_voting: eletronicVoting,
                address: address,
                user: worker?.split("/"),
                person_type: personType,
                code: code,
                date_of_birth: dobA,
                date_of_birth_code: dob,
            });
        }
    });

    shareholders = shareholders.filter(
        (shareholder, index, self) =>
            index ===
            self.findIndex(
                (t) =>
                    t.no === shareholder.no &&
                    t.registration === shareholder.registration &&
                    t.name === shareholder.name &&
                    t.shares === shareholder.shares &&
                    t.shares_total === shareholder.shares_total
            )
    );

    return shareholders;
}
