import React, { useEffect, useState } from "react";
import done from "../../../../assets/images/done.jpg";

function FileDisplay({ item }) {
	const [url, setUrl] = useState(false);

	useEffect(() => {
		const getFileThumb = async () => {
			if (item == 200) {
				setUrl(done);
			} else {
				setUrl(`${process.env.REACT_APP_STORAGE_PATH}${item}`);
			}
		};

		getFileThumb();
	}, []);

	return (
		url && (
			<img
				src={url}
				alt={url}
				className="thumbnail object-cover object-center absolute top-0 left-0"
			/>
		)
	);
}

export default FileDisplay;
