import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import React from "react";
import transl from "../../../components/translate";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditShareholdersUploader from "./EditShareholdersUploader";
import { useCSVDownloader } from "react-papaparse";

function EditShareholdersListDialog({ open, handleClose, csvBody }) {
	const { CSVDownloader } = useCSVDownloader();

	//const csvHeader = [
	//	transl("uuid"),
	//	`${transl("no")}.`,
	//	transl("name"),
	//	transl("registration"),
	//	transl("sex"),
	//	transl("number of shares"),
	//	transl("total Number Of Shares"),
	//	transl("contact info"),
	//	transl("database"),
	//	transl("address"),
	//	transl("worker"),
	//];
	const csvHeader = [
		transl("uuid"),
		`${transl("no")}`,
		transl("Resident Registration Number"),
		transl("Sex"),
		transl("Name"),
		transl("Number of Shares"),
		transl("Total Number of Shares"),
		transl("Eletronic Voting"),
		transl("Address"),
		transl("Contact"),
		transl("Database"),
		transl("Contact for worker"),
		transl("Worker"),
	];

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{transl("Edit Sharehoders List")}
			</DialogTitle>
			<DialogContent>
				<DialogContentText
					id="alert-dialog-description"
					sx={{ marginBottom: "20px" }}
				>
					{transl(
						"Download The Data File to edit information about shareholders, once the updates are done, you can upload the excel on the tool below."
					)}
				</DialogContentText>
				<EditShareholdersUploader handleClose={handleClose} />
			</DialogContent>
			<div className="flex justify-between p-4">
				<CSVDownloader
					filename={transl("Download CSV Example")}
					bom={true}
					quotes={true}
					config={{
						quotes: true,
					}}
					data={{
						fields: csvHeader,
						data: csvBody,
					}}
				>
					<Button variant="outlined">
						<FileDownloadIcon />
						Download Data File
					</Button>
				</CSVDownloader>
				<Button onClick={handleClose} autoFocus>
					Close
				</Button>
			</div>
		</Dialog>
	);
}

export default EditShareholdersListDialog;
